import React from 'react';
import Layout from '../../components/Layout/Layout';
import Header from '../../components/Layout/Header';
import Seo from '../../components/Layout/Seo';
import ImageFromNewt from '../../components/Atomic/ImageFromNewt';
import { graphql } from 'gatsby';

import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { Tab } from "@headlessui/react"


const Standerd = ({ data, pageContext }) => {
    const {
        breadcrumb: { crumbs },
    } = pageContext

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ")
    }

    const pannelContentA = data.allNewtStanderd.edges.filter((edge)=>{
        return edge.node.type === "計量器具"
    })
    const pannelContentB = data.allNewtStanderd.edges.filter((edge)=>{
        return edge.node.type === "調味料"
    })
    const pannelContentC = data.allNewtStanderd.edges.filter((edge)=>{
        return edge.node.type === "食材"
    })


    return (
        <>
            <Layout>
                <Header />
                <div className="max-w-screen-md m-auto py-16 px-6 min-h-screen">
                    <div className="mb-12">
                        <Breadcrumb
                            crumbs={crumbs}
                            crumbSeparator=" / "
                            crumbLabel={"分量の基準"}
                        />
                    </div>
                    <div className="flex ">
                        <h1 className="text-2xl markerTitle inline-block text-center m-auto font-gothic">分量の基準</h1>
                    </div>
                    <div className='py-12 entry-content'>
                        <p className='mb-12'>ohmycottieのレシピで使用する計量基準です。レシピ内に特別な表記がない限りは、ここで紹介する方法を基準としています。</p>

                        <Tab.Group>
                            <Tab.List className="flex rounded-full bg-[#E2E2E2] p-1 mb-8">
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            "w-full rounded-full py-2.5 text-sm font-medium",
                                            "focus:outline-none",
                                            selected
                                                ? "bg-[#313131] text-white shadow"
                                                : "text-omcDark hover:bg-white/[0.12] hover:text-white"
                                        )
                                    }
                                >
                                    計量機器
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            "w-full rounded-full py-2.5 text-sm font-medium",
                                            "focus:outline-none",
                                            selected
                                                ? "bg-[#313131] text-white shadow"
                                                : "text-omcDark hover:bg-white/[0.12] hover:text-white"
                                        )
                                    }
                                >
                                    調味料
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            "w-full rounded-full py-2.5 text-sm font-medium",
                                            "focus:outline-none",
                                            selected
                                                ? "bg-[#313131] text-white shadow"
                                                : "text-omcDark hover:bg-white/[0.12] hover:text-white"
                                        )
                                    }
                                >
                                    食材
                                </Tab>

                            </Tab.List>

                            <Tab.Panels className="mt-2 pb-16">
                                <Tab.Panel>
                                <div className='grid gap-8'>
                                {pannelContentA.map((edge, index) => {
                                    return (
                                        <div className="grid grid-cols-4 gap-4 place-items-start border border-omcLightGray md:p-8 p-4 rounded-lg" key={index}>
                                            <div className='inline-block bg-omcBlueGray md:p-4 p-2 rounded-lg'>
                                                <ImageFromNewt
                                                    src={edge.node.icon.src}
                                                    alt={edge.node.name}
                                                />
                                            </div>
                                            <div className='col-span-3'>
                                                <h3 className='text-omcBlueGray font-bold leading-[1] pb-2'>{edge.node.name}</h3>
                                                <p className='text-sm'>{edge.node.description}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                <div className='grid gap-8'>
                                {pannelContentB.map((edge, index) => {
                                    return (
                                        <div className="grid grid-cols-4 gap-4 place-items-start border border-omcLightGray md:p-8 p-4 rounded-lg" key={index}>
                                            <div className='inline-block bg-omcBlueGray md:p-4 p-2 rounded-lg'>
                                                <ImageFromNewt
                                                    src={edge.node.icon.src}
                                                    alt={edge.node.name}
                                                />
                                            </div>
                                            <div className='col-span-3'>
                                                <h3 className='text-omcBlueGray font-bold leading-[1] pb-2'>{edge.node.name}</h3>
                                                <p className='text-sm'>{edge.node.description}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                <div className='grid gap-8'>
                                {pannelContentC.map((edge, index) => {
                                    return (
                                        <div className="grid grid-cols-4 gap-4 place-items-start border border-omcLightGray md:p-8 p-4 rounded-lg" key={index}>
                                            <div className='inline-block bg-omcBlueGray md:p-4 p-2 rounded-lg'>
                                                <ImageFromNewt
                                                    src={edge.node.icon.src}
                                                    alt={edge.node.name}
                                                />
                                            </div>
                                            <div className='col-span-3'>
                                                <h3 className='text-omcBlueGray font-bold leading-[1] pb-2'>{edge.node.name}</h3>
                                                <p className='text-sm'>{edge.node.description}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                </div>
                                </Tab.Panel>
                            </Tab.Panels>
                            
                        </Tab.Group>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Standerd;

export const Head = () => <Seo title="分量の基準" description={"ohmycottieのレシピで使用する計量基準です。レシピ内に特別な表記がない限りは、ここで紹介する方法を基準としています。"} />


export const standerd = graphql`
query Standerd {
    allNewtStanderd {
      edges {
        node {
          description
          name
          type
          icon {
            src
          }
        }
      }
    }
  }
  
`

